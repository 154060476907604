import { useState, createContext } from 'react';

const AppContext = createContext();

const AppContextProvider = (props) => {
  const { children } = props;

  const questionsInitialState = [
    { answer: '' },
    { answer: '' },
    { answer: '' },
    { answer: '' },
    { answer: '' },
    { answer: '', emailCheckbox: false },
  ];

  const [questionsAnswers, setQuestionsAnswers] = useState(
    questionsInitialState,
  );
  const resetQuestions = () => setQuestionsAnswers(questionsInitialState);

  const timerInitialState = 120;
  const [timer, setTimer] = useState(timerInitialState);
  const resetTimer = () => setTimer(timerInitialState);

  const contextValue = {
    questionsAnswers,
    setQuestionsAnswers,
    resetQuestions,
    timer,
    setTimer,
    resetTimer,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
