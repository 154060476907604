import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { AppContextProvider } from 'context/AppContext';
import { CardContextProvider } from 'context/CardContext';
import { ErrorContextProvider } from 'context/ErrorContext';
import { LoadingContextProvider } from 'context/LoadingContext';
import Intro from './pages/Intro';
import Process from './pages/Process';
import Questions from './pages/Questions';
import Loading from './pages/Loading';
import NameReveal from 'pages/NameReveal';
import QRCodeComponent from './pages/QRCode';
import Generating from './pages/Generating';
import AiImage from 'pages/AiImage';
import ServerDown from 'pages/ServerDown';
import APIDown from 'pages/APIDown';
import Slideshow from 'pages/Slideshow';
import RecordAdmin from 'pages/RecordAdmin';
import PromptGenerator from 'pages/PromptGenerator';
import './styles/main.scss';

// hiding all but the public image endpoint for the public
const paths =
  process.env.REACT_APP_ENVIRONMENT === 'public'
    ? [
        {
          path: '/generating/:id',
          element: <Generating />,
        },
        {
          path: '/ai-image/:id',
          element: <AiImage />,
        },
      ]
    : [
        {
          path: '/',
          element: <Intro />,
        },
        {
          path: '/process',
          element: <Process />,
        },
        {
          path: '/questions',
          element: <Questions />,
        },
        {
          path: '/loading',
          element: <Loading />,
        },
        {
          path: '/name-reveal',
          element: <NameReveal />,
        },
        {
          path: '/qr-code',
          element: <QRCodeComponent />,
        },
        {
          path: '*',
          element: <Navigate to="/" replace={true} />,
        },
        {
          path: '/generating/:id',
          element: <Generating />,
        },
        {
          path: '/ai-image/:id',
          element: <AiImage />,
        },
        {
          path: '/server-down',
          element: <ServerDown />,
        },
        {
          path: '/api-down',
          element: <APIDown />,
        },

        // extra pages from Scott for the installation
        {
          path: '/slideshow',
          element: <Slideshow />,
        },

        {
          path: '/record-admin',
          element: <RecordAdmin />,
        },

        {
          path: '/prompt-generator',
          element: <PromptGenerator />,
        },
      ];

export const sitemap = createBrowserRouter(paths);

function App(props) {
  return (
    <ErrorContextProvider>
      <LoadingContextProvider>
        <AppContextProvider>
          <CardContextProvider>
            <div className="App">
              <RouterProvider router={sitemap} {...props} />
            </div>
          </CardContextProvider>
        </AppContextProvider>
      </LoadingContextProvider>
    </ErrorContextProvider>
  );
}

export default App;
