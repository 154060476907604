import { useParams } from 'react-router-dom';
import MobileLayout from '../../components/mobileLayout';
import ShowIcon from 'components/ShowIcon';
import downloadIcon from '../../assets/icons/download-icon.png';
import AiImageRectangle from '../../assets/images/AiImageRectangle.png';
import { downloadImage } from 'helpers/downloadImage';
import { getName } from 'helpers/getName';
import './styles.scss';

export default function AiImage() {
  const { id } = useParams();
  const image = `https://storage.googleapis.com/awaypostcardstorage/${id}.png`;
  const name = getName(id);

  return (
    <>
      <MobileLayout className="Ai">
        <div className="ai-container">
          <p className="ai-title">
            Share with your friends <br />
            and tag @away
          </p>
          <img
            className="ai-image"
            src={image ? image : AiImageRectangle}
            alt="generated-ai"
          />
          <div className="ai-social-medias">
            <div
              onClick={() => downloadImage(image, name ? name : 'New World')}
              style={{ cursor: 'pointer' }}
            >
              <ShowIcon
                image={downloadIcon}
                alt={'download-icon'}
                width={'35'}
                height={'35'}
              />
            </div>
          </div>
        </div>
      </MobileLayout>
    </>
  );
}
