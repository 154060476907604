export const getName = (name) => {
  const away = 'Away - ';

  let arrName = name.split('-');
  arrName.pop();
  let newName = [...arrName];
  for (var i = 0; i < newName.length; i++) {
    newName[i] = newName[i][0].toUpperCase() + newName[i].substring(1);
  }

  return away + newName.join(' ');
};
