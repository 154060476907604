export const downloadImage = (imageUrl, name) => {
  fetch(imageUrl).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `${name}.png`;
      alink.click();
    });
  });
};
