export default function ShowIcon({ image, alt, height, width }) {
  return (
    <>
      <img
        height={height}
        width={width}
        src={image}
        alt={alt}
      />
    </>
  );
}