import { createContext, useState } from 'react';

const LoadingContext = createContext();

const LoadingContextProvider = (props) => {
  const { children } = props;

  const [loading, setLoading] = useState(null);

  const data = {
    loading,
    setLoading,
  };

  return <LoadingContext.Provider value={data}>{children}</LoadingContext.Provider>;
};

export { LoadingContext, LoadingContextProvider };
