import { useEffect } from 'react';

export const useRightClick = () => {
  useEffect(() => {
    // prevents the default right-click menu from appearing
    function handleContextMenu(e) {
      e.preventDefault();
    }
    const rootElement = document.getElementById('root');
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
}
