import GeneratingBG from 'assets/images/mobileGeneratingBackgroundV2.jpg';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { ErrorContext } from 'context/ErrorContext';
import { getCard } from 'services';
import { useRightClick } from 'hooks/useRightClick';
import './styles.scss';

export default function Generating() {
  const { setError } = useContext(ErrorContext);
  const [time, setTime] = useState(20);

  const navigate = useNavigate();
  const { id } = useParams();

  useRightClick();

  useEffect(() => {
    let interval = null;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((sec) => sec - 1);
      }, 1000);
    }
    if (time === 0) {
      interval = setInterval(() => {
        setTime(20);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    async function fetchData() {
      const cardIsReady = await getCard(id);
      if (cardIsReady.get === true) {
        setTimeout(() => {
          return navigate(`/ai-image/${id}`);
        }, 2000);
      }
      setError(cardIsReady.message);
    }
    if (time === 20) {
      fetchData();
    }
  }, [id, setError, time, navigate]);

  return (
    <>
      <div className="generating-container">
        <div
          className="image-background"
          style={{ backgroundImage: `url(${GeneratingBG})` }}
        >
          <div className="mesage-container">
            <p className="message">
              Your <span className="world">world</span>
              <br /> is generating...
            </p>
            <p className="time-message">Approx. 2 min wait</p>
          </div>
        </div>
      </div>
    </>
  );
}
