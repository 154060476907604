import axios from 'axios';
import { testImageUrl } from 'helpers/constants';

export const getCard = async (id) => {
  const url = `https://storage.googleapis.com/awaypostcardstorage/${id}.png`;
  const imageUrl = id ? url : testImageUrl;

  try {
    const newAiCard = await axios
      .get(imageUrl, {
        responseType: 'blob',
      })
      .then((res) => ({ get: true, data: res?.status }))
      .catch((err) => ({ get: false, data: err?.request?.status }));

    return newAiCard;
  } catch (e) {
    return { get: false, message: e };
  }
};
