import { createContext, useState } from 'react';

const ErrorContext = createContext();

const ErrorContextProvider = (props) => {
  const { children } = props;

  const [error, setError] = useState(null);

  const data = {
    error,
    setError,
  };

  return <ErrorContext.Provider value={data}>{children}</ErrorContext.Provider>;
};

export { ErrorContext, ErrorContextProvider };
