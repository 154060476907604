const extraWords = [
  'a55',
  'a55hole',
  'aeolus',
  'ahole',
  'anal',
  'analprobe',
  'anilingus',
  'anus',
  'areola',
  'areole',
  'arian',
  'aryan',
  'ass',
  'assbang',
  'assbanged',
  'assbangs',
  'asses',
  'assfuck',
  'assfucker',
  'assh0le',
  'asshat',
  'assho1e',
  'ass hole',
  'assholes',
  'assmaster',
  'assmunch',
  'asswipe',
  'asswipes',
  'azazel',
  'azz',
  'b1tch',
  'babe',
  'babes',
  'ballsack',
  'bang',
  'banger',
  'barf',
  'bastard',
  'bastards',
  'bawdy',
  'beaner',
  'beardedclam',
  'beastiality',
  'beatch',
  'beater',
  'beaver',
  'beer',
  'beeyotch',
  'beotch',
  'biatch',
  'bigtits',
  'big tits',
  'bimbo',
  'bitch',
  'bitched',
  'bitches',
  'bitchy',
  'blow job',
  'blow',
  'blowjob',
  'blowjobs',
  'bod',
  'bodily',
  'boink',
  'bollock',
  'bollocks',
  'bollok',
  'bone',
  'boned',
  'boner',
  'boners',
  'bong',
  'boob',
  'boobies',
  'boobs',
  'booby',
  'booger',
  'bookie',
  'bootee',
  'bootie',
  'booty',
  'booze',
  'boozer',
  'boozy',
  'bosom',
  'bosomy',
  'bowel',
  'bowels',
  'bra',
  'brassiere',
  'breast',
  'breasts',
  'bugger',
  'bukkake',
  'bullshit',
  'bull shit',
  'bullshits',
  'bullshitted',
  'bullturds',
  'bung',
  'busty',
  'butt',
  'butt fuck',
  'buttfuck',
  'buttfucker',
  'buttfucker',
  'buttplug',
  'c.0.c.k',
  'c.o.c.k.',
  'c.u.n.t',
  'c0ck',
  'c-0-c-k',
  'caca',
  'cahone',
  'cameltoe',
  'carpetmuncher',
  'cawk',
  'cervix',
  'chinc',
  'chincs',
  'chink',
  'chink',
  'chode',
  'chodes',
  'cl1t',
  'climax',
  'clit',
  'clitoris',
  'clitorus',
  'clits',
  'clitty',
  'cocain',
  'cocaine',
  'cock',
  'c-o-c-k',
  'cockblock',
  'cockholster',
  'cockknocker',
  'cocks',
  'cocksmoker',
  'cocksucker',
  'cock sucker',
  'coital',
  'commie',
  'condom',
  'coon',
  'coons',
  'corksucker',
  'crabs',
  'crack',
  'cracker',
  'crackwhore',
  'crap',
  'crappy',
  'cum',
  'cummin',
  'cumming',
  'cumshot',
  'cumshots',
  'cumslut',
  'cumstain',
  'cunilingus',
  'cunnilingus',
  'cunny',
  'cunt',
  'cunt',
  'c-u-n-t',
  'cuntface',
  'cunthunter',
  'cuntlick',
  'cuntlicker',
  'cunts',
  'd0ng',
  'd0uch3',
  'd0uche',
  'd1ck',
  'd1ld0',
  'd1ldo',
  'dago',
  'dagos',
  'dammit',
  'damn',
  'damned',
  'damnit',
  'dawgie-style',
  'dick',
  'dicks',
  'poo',
  'poos',
  'dickbag',
  'dickdipper',
  'dickface',
  'dickflipper',
  'dickhead',
  'dickheads',
  'dickish',
  'dick-ish',
  'dickripper',
  'dicksipper',
  'dickweed',
  'dickwhipper',
  'dickzipper',
  'diddle',
  'dike',
  'dildo',
  'dildos',
  'diligaf',
  'dillweed',
  'dimwit',
  'dingle',
  'dipship',
  'doggie-style',
  'doggy-style',
  'dong',
  'doofus',
  'doosh',
  'dopey',
  'douch3',
  'douche',
  'douchebag',
  'douchebags',
  'douchey',
  'drunk',
  'dumass',
  'dumbass',
  'dumbasses',
  'dummy',
  'dyke',
  'dykes',
  'ejaculate',
  'enlargement',
  'erect',
  'erection',
  'erotic',
  'essohbee',
  'extacy',
  'extasy',
  'f.u.c.k',
  'fack',
  'fag',
  'fagg',
  'fagged',
  'faggit',
  'faggot',
  'fagot',
  'fags',
  'faig',
  'faigt',
  'fannybandit',
  'fart',
  'fartknocker',
  'fat',
  'felch',
  'felcher',
  'felching',
  'fellate',
  'fellatio',
  'feltch',
  'feltcher',
  'fisted',
  'fisting',
  'fisty',
  'floozy',
  'foad',
  'fondle',
  'foobar',
  'foreskin',
  'freex',
  'frigg',
  'frigga',
  'fubar',
  'fuck',
  'f-u-c-k',
  'fuckass',
  'fucked',
  'fucked',
  'fucker',
  'fuckface',
  'fuckin',
  'fucking',
  'fucknugget',
  'fucknut',
  'fuckoff',
  'fucks',
  'fucktard',
  'fuck-tard',
  'fuckup',
  'fuckwad',
  'fuckwit',
  'fudgepacker',
  'fuk',
  'fvck',
  'fxck',
  'gae',
  'gai',
  'ganja',
  'gay',
  'gays',
  'gey',
  'gfy',
  'ghay',
  'ghey',
  'gigolo',
  'glans',
  'goatse',
  'godamn',
  'godamnit',
  'goddam',
  'goddammit',
  'goddamn',
  'goldenshower',
  'gonad',
  'gonads',
  'gook',
  'gooks',
  'gringo',
  'gspot',
  'g-spot',
  'gtfo',
  'guido',
  'h0m0',
  'h0mo',
  'handjob',
  'hard on',
  'he11',
  'hebe',
  'heeb',
  'hell',
  'hemp',
  'heroin',
  'herp',
  'herpes',
  'herpy',
  'hitler',
  'hiv',
  'hobag',
  'hom0',
  'homey',
  'homo',
  'homoey',
  'honky',
  'hooch',
  'hookah',
  'hooker',
  'hoor',
  'hootch',
  'hooter',
  'hooters',
  'horny',
  'hump',
  'humped',
  'humping',
  'hussy',
  'hymen',
  'inbred',
  'incest',
  'injun',
  'j3rk0ff',
  'jackass',
  'jackhole',
  'jackoff',
  'jap',
  'japs',
  'jerk',
  'jerk0ff',
  'jerked',
  'jerkoff',
  'jism',
  'jiz',
  'jizm',
  'jizz',
  'jizzed',
  'junkie',
  'junky',
  'kike',
  'kikes',
  'kill',
  'kinky',
  'kkk',
  'klan',
  'knobend',
  'kooch',
  'kooches',
  'kootch',
  'kraut',
  'kyke',
  'labia',
  'lech',
  'leper',
  'lesbians',
  'lesbo',
  'lesbos',
  'lez',
  'lezbian',
  'lezbians',
  'lezbo',
  'lezbos',
  'lezzie',
  'lezzies',
  'lezzy',
  'lmao',
  'lmfao',
  'loin',
  'loins',
  'lube',
  'lusty',
  'mams',
  'massa',
  'masterbate',
  'masterbating',
  'masterbation',
  'masturbate',
  'masturbating',
  'masturbation',
  'maxi',
  'menses',
  'menstruate',
  'menstruation',
  'meth',
  'm-fucking',
  'mofo',
  'molest',
  'moolie',
  'moron',
  'motherfucka',
  'motherfucker',
  'motherfucking',
  'mtherfucker',
  'mthrfucker',
  'mthrfucking',
  'muff',
  'muffdiver',
  'murder',
  'muthafuckaz',
  'muthafucker',
  'mutherfucker',
  'mutherfucking',
  'muthrfucking',
  'nad',
  'nads',
  'naked',
  'napalm',
  'nappy',
  'nazi',
  'nazism',
  'negro',
  'nigga',
  'niggah',
  'niggas',
  'niggaz',
  'nigger',
  'nigger',
  'niggers',
  'niggle',
  'niglet',
  'nimrod',
  'ninny',
  'nipple',
  'nooky',
  'nympho',
  'opiate',
  'opium',
  'oral',
  'orally',
  'organ',
  'orgasm',
  'orgasmic',
  'orgies',
  'orgy',
  'ovary',
  'ovum',
  'ovums',
  'p.u.s.s.y.',
  'paddy',
  'paki',
  'pantie',
  'panties',
  'panty',
  'pastie',
  'pasty',
  'pcp',
  'pecker',
  'pedo',
  'pedophile',
  'pedophilia',
  'pedophiliac',
  'pee',
  'peepee',
  'penetrate',
  'penetration',
  'penial',
  'penile',
  'penis',
  'perversion',
  'peyote',
  'phalli',
  'phallic',
  'phuck',
  'pillowbiter',
  'pimp',
  'pinko',
  'piss',
  'pissed',
  'pissoff',
  'piss-off',
  'pms',
  'polack',
  'pollock',
  'poon',
  'poontang',
  'porn',
  'porno',
  'pornography',
  'pot',
  'potty',
  'prick',
  'prig',
  'prostitute',
  'prude',
  'pube',
  'pubic',
  'pubis',
  'punkass',
  'punky',
  'puss',
  'pussies',
  'pussy',
  'pussypounder',
  'puto',
  'queaf',
  'queef',
  'queef',
  // "queer",
  'queero',
  'queers',
  'quicky',
  'quim',
  'racy',
  'rape',
  'raped',
  'raper',
  'rapist',
  'raunch',
  'rectal',
  'rectum',
  'rectus',
  'reefer',
  'reetard',
  'reich',
  'retard',
  'retarded',
  'revue',
  'rimjob',
  'ritard',
  'rtard',
  'r-tard',
  'rum',
  'rump',
  'rumprammer',
  'ruski',
  's.h.i.t.',
  's.o.b.',
  's0b',
  'sadism',
  'sadist',
  'scag',
  'scantily',
  'schizo',
  'schlong',
  'screw',
  'screwed',
  'scrog',
  'scrot',
  'scrote',
  'scrotum',
  'scrud',
  'scum',
  'seaman',
  'seamen',
  'seduce',
  'semen',
  'sex',
  'sexual',
  'sh1t',
  's-h-1-t',
  'shamedame',
  'shit',
  's-h-i-t',
  'shite',
  'shiteater',
  'shitface',
  'shithead',
  'shithole',
  'shithouse',
  'shits',
  'shitt',
  'shitted',
  'shitter',
  'shitty',
  'shiz',
  'sissy',
  'skag',
  'skank',
  'slave',
  'sleaze',
  'sleazy',
  'slut',
  'slutdumper',
  'slutkiss',
  'sluts',
  'smegma',
  'smut',
  'smutty',
  'snatch',
  'sniper',
  'snuff',
  's-o-b',
  'sodom',
  'souse',
  'soused',
  'sperm',
  'spic',
  'spick',
  'spik',
  'spiks',
  'spooge',
  'spunk',
  'steamy',
  'stfu',
  'stiffy',
  'stoned',
  'strip',
  'stroke',
  'stupid',
  'suck',
  'sucked',
  'sucking',
  'sumofabiatch',
  't1t',
  'tampon',
  'tard',
  'tawdry',
  'teabagging',
  'teat',
  'terd',
  'teste',
  'testee',
  'testes',
  'testicle',
  'testis',
  'thrust',
  'thug',
  'tinkle',
  'tit',
  'titfuck',
  'titi',
  'tits',
  'tittiefucker',
  'titties',
  'titty',
  'tittyfuck',
  'tittyfucker',
  'toke',
  'toots',
  'tramp',
  'transsexual',
  'trashy',
  'tubgirl',
  'turd',
  'tush',
  'twat',
  'twats',
  'ugly',
  'undies',
  'urinal',
  'urine',
  'uterus',
  'uzi',
  'vag',
  'vagina',
  'valium',
  'viagra',
  'virgin',
  'vodka',
  'vomit',
  'voyeur',
  'vulgar',
  'vulva',
  'wad',
  'wang',
  'wank',
  'wanker',
  'wazoo',
  'wedgie',
  'weed',
  'weenie',
  'weewee',
  'weiner',
  'wench',
  'wetback',
  'wh0re',
  'wh0reface',
  'whitey',
  'whiz',
  'whoralicious',
  'whore',
  'whorealicious',
  'whored',
  'whoreface',
  'whorehopper',
  'whorehouse',
  'whores',
  'whoring',
  'wigger',
  'womb',
  'woody',
  'wop',
  'wtf',
  'x-rated',
  'xxx',
  'yeasty',
  'yobbo',
  'zoophile',
];

const carBrands = [
  'Toyota',
  'Ford',
  'Chevrolet',
  'Honda',
  'Nissan',
  'Jeep',
  'Hyundai',
  'Kia',
  'Ram Trucks',
  'Subaru',
  'GMC',
  'Volkswagen',
  'BMW',
  'Mazda',
  'Mercedes-Benz',
  'Lexus',
  'Tesla',
  'Dodge',
  'Audi',
  'Buick',
  'Acura',
  'Volvo',
  'Cadillac',
  'Chrysler',
  'Mitsubishi',
  'Land Rover',
  'Lincoln',
  'Porsche',
  'Infiniti',
  'Genesis',
  'Mini',
  'Maserati',
  'Alfa Romeo',
  'Jaguar',
  'Bentley',
  'Ferrari',
  'Lamborghini',
  'Aston Martin',
  'Polestar',
  'Fiat',
  'Rolls-Royce',
  'McLaren',
  'Lucid',
  'Bugatti',
  'Lotus',
  'Rivian',
  'Canoo',
  'Pontiac',
  'Oldsmobile',
  'Rimac',
];

const sodaBrands = [
  'Coca-Cola Company',
  'Coca-Cola Clear',
  'Coca',
  'Ambasa',
  'Ameyal',
  'Appletiser',
  'Aquarius',
  "Barq's",
  'Beat',
  'Beverly',
  'Coca-Cola',
  'Coca-Cola Zero',
  'Coke',
  'Campa',
  'Dasani',
  'Fanta',
  'Fresca',
  'Frescolihi',
  'Frescolita',
  'Leed',
  'Lilt',
  'Limca',
  'Mello Yello',
  'Moxie',
  'Mr. Pibb',
  'Powerade',
  'Qoo',
  'Sprite',
  'Surge',
  'Tab',
  'Thums Up',
  'Vault',
  'Pepsi',
  'Mirinda',
  'AMP Energy',
  "Duke's",
  'Fayrouz',
  'Gatorade',
  'Mountain Dew',
  'KickStart',
  'MDX',
  'Mug Root Beer',
  'Seaman',
  'Sierra Mist',
  'Keurig',
  'Dr Pepper',
  '7 Up',
  'A&W',
  'Barrelhead',
  'Cactus Cooler',
  'Canada Dry',
  "Canfield's",
  'Crush',
  'Gini',
  'Hawaiian Punch',
  'Hires Root Beer',
  'IBC Root Beer',
  'Orangina',
  'RC Cola',
  'Diet Rite',
  'Nehi',
  'Ricqlès',
  'Schweppes',
  'Squirt',
  "Stewart's",
  'Sun Drop',
  'Sunkist',
  'Sussex Golden',
  'Venom',
  'Vernors',
  'Wink',
  'Hamoud',
  'Hamoud',
  'Selecto',
  'Slim',
  'Lim',
  'o’ju',
  'Faygo',
  'La Croix',
  'Shasta',
  'Barr',
  'Irn-Bru',
  'Ale-8-One',
  'Bawls',
  'Big Red',
  'Cheerwine',
  "Dr Brown's",
  'Jarritos',
  'Jones',
  'Julebrus',
  'Julmust',
  'Kinnie',
  'Lucozade',
  'Monster',
  'Moxie',
  'Nehi',
  'Orangina',
  'Oronamin',
  'Pocari Sweat',
  'Rockstar',
  'Sidral',
  'Sodastream',
  'Sosyo',
  'Tizer',
  'Breizh',
  'Jolt',
  'Mecca',
  'RC',
  'Topsia',
  'Virgin',
  'Corona',
  'Cresta',
  'Britvic',
  'Sprite',
  'Ramune',
  'Tango',
  'Kinnie',
  'Quatro',
  'Squirt',
  'Tropicana',
  'Tw!ster',
  'Aquafina',
  'Bailey',
  'Bilbor',
  'Bisleri',
  'Borsec',
  'Buxton',
  'Buziaș',
  'Damavand',
  'Fiji',
  'Highland Spring',
  'Kingfisher',
  'Kinley water',
  'Masafi',
  'Nestle',
  'Perrier',
  'Vittel',
  'Volvic',
  'Voss',
  'Copella',
  "Cottee's",
  'Crusha',
  'Frijj',
  'Izze',
  'J2O',
  'Kool-Aid',
  'Minute Maid',
  "Mott's",
  'Orangina',
  'Pago',
  'Pet',
  'Robinsons',
  'Suntop',
  'Taste Farm',
  'Yazoo Chill',
  'Yoo-hoo',
  'Bacon soft drink',
  'Bludwine',
  'Budwine',
  'Liptonice',
];

const beerBrands = [
  'Busch Light',
  'Keystone Light',
  'Miller64',
  'Michelob Ultra',
  'Busch',
  'Miller',
  'Miller Lite',
  'Miller High Life',
  'Coors Light',
  'Michelob Light',
  'Coors',
  'Bud',
  'Bud Light',
  'Tecate',
  'White Claw',
  'Corona Light',
  'Budweiser',
  'Molson Canadian',
  "Foster's",
  'Pabst Blue Ribbon',
  'PBR',
  'Dos Equis',
  'St. Pauli Girl',
  'Modelo',
  'Rolling Rock',
  'Heineken',
  'Bud Light Lime',
  'Amstel',
  'Modelo Negra',
  'Yuengling',
  'Heineken',
  'Stella Artois',
  "Beck's",
  'Ale',
  'Lager',
  'Guinness',
  'Blue Moon',
  'Samuel Adams',
  'Sam Adams',
  'Fat Tire',
  'Great Lakes',
];

const liquorBrands = [
  'Aperol',
  'Jägermeister',
  'Gordon',
  'Morosha',
  'Blender',
  'McDowell',
  'Ballantine',
  'Baileys',
  'Crown Royal',
  'Hlibny',
  'Jameson',
  '8PM',
  'Zubrówka',
  'Khortytsa',
  'Jim Beam',
  'Absolut',
  'Haywards',
  'Captain Morgan',
  'Jack Daniel',
  'Johnnie Walker',
  'Bacardi',
  'Royal Stag',
  'Officer’s Choice',
  'Emperador',
  'Tanduay',
  'Imperial Blue',
  'Smirnoff',
  'Ginebra',
  'Jinro',
];

const majorBrands = [
  'Google',
  'Amazon',
  'Microsoft',
  'Tencent',
  'McDonald',
  "McDonald's",
  'Visa',
  'Meta',
  'Facebook',
  'Alibaba',
  'Vuitton',
  'Samsung',
  'Toyota',
  'Coca-Cola',
  'Coca',
  'Mercedes-Benz',
  'Mercedes',
  'Disney',
  'Nike',
  'Walmart',
  'ICBC',
  'Huawei',
  'Verizon',
  'Google',
  'General Electric',
  'Microsoft',
  'Coca-Cola',
  'Marlboro',
  'Walmart',
  'Citigroup',
  'IBM',
  'Toyota',
  'General Electric',
  'GE',
  'Microsoft',
  'Coca-Cola',
  'IBM',
  'Nokia',
  'Marlboro',
  'Vodafone',
  'AT&T',
  'Verizon',
  'Tencent',
  'Alibaba',
  'Mastercard',
  'Intel',
  'Cisco',
  'Nokia',
  'Toyota',
  'Citi',
  'Ford',
  'Gillette',
  'Sony',
  'American Express',
  'Honda',
];

const religiousTerms = [
  "Bahá'í",
  'Buddhist',
  'Christian',
  'Islamic',
  'Shinbutsu',
  'shūgō',
  'Chinjusha',
  'Gion',
  'Hachiman',
  'Inari',
  'Jingū-ji',
  'Kōshin',
  'Kumano',
  'Onmyōdō',
  'Sannō',
  'Ichijitsu',
  'Shintō',
  'Shinbutsu',
  'Shugendō',
  'Vairocana',
  'Shinto',
  'Judaism',
  'Chabad',
  'Hebrew',
  'Talmud',
  'Repentance',
  'Shinto',
  'Sikh',
  'Wiccan',
  'Wicca',
  'Zoroastrian',
  'Abbot',
  'Abomination',
  'Abomination',
  'Judaism',
  'desolation',
  'Abthain',
  'Abyss',
  'Alakh',
  'Niranjan',
  'Amen',
  'Apocalypticism',
  'Apophatic',
  'Asceticism',
  'Blasphemy',
  'Cataphatic',
  'Caudatario',
  'Chalice',
  'Charisma',
  'Charnel',
  'Clergy',
  'Communion',
  'Covenant',
  'Creed',
  'Demon',
  'Divine',
  'Exegesis',
  'Satan',
  'Hellfire',
  'Heresy',
  'Hermeneutics',
  'Holy',
  'Hubris',
  'Impiety',
  'Infidel',
  'Inshallah',
  'Laity',
  'Moses',
  'Gaib',
  'Martyr',
  'Melchizedek',
  'Messiah',
  'Messianism',
  'Midrash',
  'Miracle',
  'Moksha',
  'Numinous',
  'Ordination',
  'Orthodoxy',
  'Prayer',
  'Preacher',
  'Priest',
  'Propitiation',
  "R'amen",
  'Redemption',
  'Repentance',
  'Revelation',
  'Sacrificial',
  'Sādhu',
  'Saint',
  'Salvation',
  'Sanctification',
  'Sin',
  'God',
  'Supplication',
  'Theophany',
  'Tithe',
  'Transfiguration',
  'Universalism',
];

const religiousGroups = [
  "Christianity",
  "Christian",
  "Abrahamic",
  "Judaea",
  "Jewish",
  "Islam",
  "Islamic",
  "Arabia",
  "Middle East",
  "Hinduism",
  "Hindu",
  "Buddhism",
  "Buddha",
  "Shinto",
  "Taoism",
  "Vodou",
  "Dahomey",
  "Sikhism",
  "Sikh",
  "Judaism",
  "Judah",
  "Judist",
  "Spiritism",
  "Confucious",
  "Baháʼí",
  "Jainism",
  "Cheondoism",
  "Hoahaoism",
  "Caodaism",
  "Tenriism",
  "Druze",
];

const banList = [
  ...extraWords,
  ...carBrands,
  ...sodaBrands,
  ...beerBrands,
  ...liquorBrands,
  ...majorBrands,
  ...religiousTerms,
  ...religiousGroups,
];

export default banList;


