import { createContext, useState } from 'react';

const CardContext = createContext();

const CardContextProvider = (props) => {
  const { children } = props;

  const [card, setCard] = useState(null);

  const data = {
    card,
    setCard,
  };

  return <CardContext.Provider value={data}>{children}</CardContext.Provider>;
};

export { CardContext, CardContextProvider };
